import { InitHeaderSticky } from '../../JavaScript/header/header-sticky'
import { InitRegionWidget } from "../../JavaScript/header/region-widget";
import { InitChoices } from "../../JavaScript/choices";
import { InitContentFadeIn } from "../../JavaScript/content-fade-in";

window.addEventListener('load', () => {
  InitHeaderSticky()
  InitRegionWidget()
  InitChoices()
  InitContentFadeIn()
})
